.ltr {
  text-align: left;
}

.editor-input {
  resize: none;
  font-size: 0.938rem;
  caret-color: rgb(5, 5, 5);
  position: relative;
  tab-size: 1;
  outline: 0;
  border-top: 1px solid #e3e6ea;
  border-bottom: 1px solid #e3e6ea;
  padding: 0.938rem 0.938rem 0.938rem 0.625rem;
  overflow: auto;
}

.editor-text-bold {
  font-weight: bold;
}

.editor-text-italic {
  font-style: italic;
}

.editor-text-underline {
  text-decoration: underline;
}

.editor-link {
  color: rgb(33, 111, 219);
  text-decoration: none;
}
